Cake.create('token',"#token",{
    root:'.table-container',
    handlers:{
        destroy(e){
            this.reset();
        },
        isConnected(){
            this.fire('getStaticToken').then((token)=>{

                this.$scope.set('token', token);
            });
        }
    },
    subscribe:{
        toolbar: {
            reset() {
                // Show password input modal
                const password = prompt('Enter password');
                if (password === '888bingo') {
                    // Password matches, proceed with reset
                    let token = this.utils.getToken();
                    this.fire('spin', 'resetStaticToken');
                    this.utils
                        .post(`/api/static_token`, {
                            headers: { 'Authorization': `Bearer ${token}` },
                        })
                        .then(res => {
                            this.fire('spinout', 'resetStaticToken');
                            if (res.status === 1) {
                                this.$scope.set('token', res.data);
                            } else {
                                console.error('Reset failed');
                            }
                        });
                    alert("Token Reset Successful! ")
                } else {
                    // Incorrect password
                    console.log('Incorrect password');
                    // alert("incorrect password")
                }
            }
        }
    },
});