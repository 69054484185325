const router = new Cake.Router({
    '/':{
        name:'login',
        display:'Login',
        components:['login-container','form'],
        auth:false,
    },
    '/dashboard':{
        name:'dashboard',
        display:'Dashboard',
        components:['header','tabs','dashboard'],
        auth:false,
    },
    // '/data':{
    //     name:'data',
    //     display:'data',
    //     components:['header','tabs','table', 'bet_summary', 'toolbar'],
    //     auth:false,
    // },
    '/maintenance':{
        name:'maintenance',
        display:'Maintenance',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '/betting_logs':{
        name:'betting_logs',
        display:'Betting Logs',
        components:['header','tabs','fuzzy','table','bet_summary','toolbar'],
        auth:false,
    },
    '/ggr':{
        name:'ggr',
        display:'GGR',
        components:['header','tabs','fuzzy','table','ggr','toolbar'],
        auth:false,
    },
    '/game_history':{
        name:'game_history',
        display:'Game History',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '/players':{
        name:'players',
        display:'Players',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '/user':{
        name:'user',
        display:'User',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '/role':{
        name:'role',
        display:'Role',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '/permission':{
        name:'permission',
        display:'Permission',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '/static_token':{
        name:'static_token',
        display:'Static Token',
        components:['header','tabs','token','toolbar'],
        auth:false,
    },
    '/pull_bingo_13ball':{
        name:'pull_bingo_13ball',
        display:'Pull Bingo 13Ball',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '/pull_bingo_bingo_pares':{
        name:'pull_bingo_bingo_pares',
        display:'Pull Bingo Bingo Pares',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '/pull_bingo_dragontiger':{
        name:'pull_bingo_dragontiger',
        display:'Pull Bingo Dragon V Tiger',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '/pull_bingo_bingoswertres':{
        name:'pull_bingo_bingoswertres',
        display:'Pull Bingo Bingo Swertres',
        components:['header','tabs','table','toolbar'],
        auth:false,
    },
    '404':function(){
        return '/user';
    },
},{
    auth:{
        '401':'login',
        verify:['api','verify'],
        valid:{
            admin:'dashboard',
            superadmin:'user',
            megaxcess:'betting_logs',
            pagcor:'betting_logs',
            csr1:'ggr',
            csr2:'ggr',
            csr3:'ggr',
            csr4:'ggr',
            csr:'ggr',
        }
    },
    components:{
        sidebar:{
            rerender:['/ggr', '/betting_logs', '/', ]
        },
        tabs:{
            rerender:['/',]
        },
        header:{
            rerender:['/',]
        }
    }
});