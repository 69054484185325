
Cake.create('table', '#table', {
    root:'.table-container',
    // animate:{
    //     'table':{
    //         render:{keyframes:['appear']}
    //     }   
    // },
    router(){
        this.data.menu = this.$router.name;
        this.data.prev = this.$router.prev && this.$router.prev.name;
    },
    data(){

        this.src = {
            officers:'local',
            products:'local',
            centers:'local',
            members:{
                query:'remote',
                data:'local',
            },
        };

        this.search = {};

    },
    handlers:{
        
        async destroy(){
            // console.log(this.data.prev);
            await this.fire('getReduce', this.data.prev).then(reduce=>{
                if (reduce.length){
                    this.data.table && this.data.table.setColumns(reduce)
                };
            });

            if (this.data.table){
                this.data.table.clearData();
                this.data.table.clearHistory();
                this.data.table.clearSort();
                
                this.data.table = null;
            };
            this.data.search = {};
            return this.reset();
            
            // return new Promise((res, rej)=>{
            //     this.fire('getReduce', this.$scope.prev).then(reduce=>{
            //         if (reduce.length){
            //             this.data.table.setColumns(reduce)
            //             res();
            //         } else {
            //             res();
            //         };
            //     });
            // }).then(()=>{
            //     if (this.data.table){
            //         this.data.table.clearData();
            //         this.data.table.clearHistory();
            //         this.data.table.clearSort();
                    
            //         this.data.table = null;
            //     };
            // }).then(()=>{
            //     return this.reset();
            // });
            
        },
        clearFilter(){
            this.$scope.set('table_searching', false);
            this.data.search = {};
            this.fire('getGGRSummary',{...this.data.bet_filter});
            this.data.table.setData();
        },
        recalc(){
            this.data.table.setData();
            this.data.table.recalc();
        },
        async updateFilters(){
            let filters = await this.fire('filters');
            this.data.search = {};
            filters.forEach(filter=>{
                let [key, value] = Object.entries(filter)[0];
                this.data.search[key] = value;
            });
            return filters
        },
        async isConnected(obj){
            let data = obj.emit && obj.emit.data;

            
            if(['betting_logs','ggr'].includes(this.data.menu)){
                // let bet_filter = await this.$globalScope.get('bet_filter');
                // this.data.bet_filter = {bet_filter};



                let filters = await this.fire.updateFilters();
                // console.log(this.data);
            };


            await this.fire.renderTable(data);
        },

        async renderTable(data){
            let {tableFields:columns, menu} = await this.fire('getUiConfig', this.data.menu);
            let settings = await this.fire('getTableSettings');


            if(this.data.menu == 'ggr'){
                settings.paginationSize = 7;
            };


            let {token} = await this.$router.auth();

            this.data.isLoaded = false;
         
            if (data){
                let _this = this;
                let config = Object.assign(settings,{
                    columns,
                    data,
                    rowFormatter:function(row){
                        row.getElement().setAttribute('data-_id', row.getData()._id);
                        row.getElement().setAttribute('data-_rev', row.getData()._rev);
                        row.getElement().setAttribute('data-isEditable', row.getData()._isEditable || true);
                    },
                });

                this.data.table = new Tabulator("#dtable", config);

                this.fire('loaded');
            } else {
                let map = {
                    betting_logs: '/api/betting_logs/paginate',
                    game_history:'/api/game_history/paginate',
                    players:'/admin/getActiveUsers',
                    user:'/api/user/paginate',
                    role:'/api/role/paginate',
                    permission:'/api/permission/paginate',
                    ggr:'/api/ggr/paginate',
                    maintenance:'/api/maintenance/paginate',
                    pull_bingo_13ball:'/api/pull_bingo/13ball/paginate',
                    pull_bingo_bingo_pares:'/api/pull_bingo/bingopares/paginate',
                    pull_bingo_dragontiger:'/api/pull_bingo/dragonvtiger/paginate',
                    pull_bingo_bingoswertres:'/api/pull_bingo/bingoswertres/paginate'

                }

                let url = map[menu];
                if (!url){
                    return false;
                };


                this.fire('spin','tableData');

                let _this = this;
                const config = Object.assign(settings,
                {
                    columns,
                    rowFormatter:function(row){
                        row.getElement().setAttribute('data-_id', row.getData()._id);
                        row.getElement().setAttribute('data-id', row.getData().id);
                        row.getElement().setAttribute('data-_rev', row.getData()._rev);
                        row.getElement().setAttribute('data-isEditable', row.getData()._isEditable || true);
                    },
                }, {
                    ajaxURL:url,
                    ajaxConfig:{
                        mode:"cors", //set request mode to cors
                        credentials: "same-origin", //send cookies with the request from the matching origin
                        method:"GET", //set request type to Position
                        headers: {
                            "Content-type": 'application/json; charset=utf-8', //set specific content type
                            credentials: 'include',
                            "tbl":menu,
                            "Authorization":`Bearer ${token}`,
                        },
                        dataLoaderLoading:`<div id=dots-container>
                            <div class="dots-2"></div>
                        </div>`,
                    },
                    ajaxResponse:(url, params, response)=>{
                        if (response.status == 0){
                            this.fire('error', response.message)
                        };
                        this.data.isLoaded = true;
                        setTimeout(()=>{
                            this.fire('spinout','tableData');
                        });
                        return response; 
                    },

                    ajaxURLGenerator:(url, config, params)=>{
                        // console.log(this.data.bet_filter);
                        return `${url}?page=${params.page}&size=${params.size}&search=${JSON.stringify({...this.data.dateFilter,...this.data.search,...this.data.bet_filter})}`;
                    },
                });


                this.data.table = new Tabulator("#dtable", config);

                this.data.table && this.data.table.on("rowClick", function(e, _row){
                    let data = _row.getData();
                    let row = _row.getElement();
                    if(e.target.closest('[name=radio-container]')){
                        let btn = e.target.closest('.button');
                        this.fire('radioClicked', {
                            id:row.dataset.id,
                            _id:row.dataset._id,
                            _rev:row.dataset._rev,
                            action:btn.getAttribute('name'),
                        });
                    } else {
                        if(menu == 'betting_logs'){
                            if(data.closed_at == null){
                                this.fire('tableRowClick',{
                                    data,root:row,
                                });
                            }
                        } else {
                            this.fire('tableRowClick',{
                                data,root:row,
                            });
                        };
                    };
                }.bind(this));
                
                this.data.table.setData();
                this.fire('loaded');
            }
            
        },
        sync(){
            if(this.data.menu == 'logs'){
                this.fire('getGGRSummary',{...this.data.dateFilter, ...this.data.search,...this.data.bet_filter});
            };
            return this.data.table.setData();
        },
        setData({tbl, data}){
            this.data.table.clearData(data);
            this.data.table.setData(data);
            // console.log(tbl, data);
        }
    },
    subscribe:{
        sidebar:{
            rendertable(menu){
                // console.log(menu);
                this.await.destroy.then(()=>{
                    this.render();
                });
            },
            destroytable(){
                // console.log('destroy table');
                this.fire.destroy();
                // location.reload();
            },
        },
        toolbar:{
            sync(){
                return this.fire.sync();
            },
            print(e){
                // console.log(e);
                this.data.table.print(false, true);
            },
            // downloadClient(){

            //     this.data.table.download("xlsx", "ggr.xlsx", {sheetName:"data"});
            // }
        },
        getCurrentFilter:{
            components:['api','paginate'],
            handler(e){
                return {...this.data.dateFilter,...this.data.search,...this.data.bet_filter};
            }
        },
        socket:{
            setData(arr){
                // console.log(arr);
                this.fire.destroy();
                this.await.destroy.then(()=>{
                    this.render({emit:{data:arr}});
                });
            }
        },
        ggr:{
            submit(e){
                this.data.search = Object.assign(this.data.search, e);
                this.data.table.setData();
            }
        },
        logsfilter:{
            submit(e){
                this.data.search = Object.assign(this.data.search, e);

                const {date_from, date_to} = {...this.data.dateFilter,...this.data.search};

                this.fire('getGGRSummary',{date_from, date_to, ...this.data.bet_filter});
                this.data.table.setData();

                this.$scope.set('table_searching', true);
            }
        },
        historyfilter:{
            submit(e){
                this.data.search = Object.assign(this.data.search, e);

                this.data.table.setData();

                this.$scope.set('table_searching', true);
            }
        },
        'filter-form':{
            submit(e){
                // console.log(e);
                this.data.search = Object.assign(this.data.search, e);

                const {datetime_from, datetime_to} = {...this.data.dateFilter,...this.data.search};


                if(this.$router.name == 'logs'){
                    if(datetime_from && datetime_to){
                        this.fire('getGGRSummary',{datetime_from, datetime_to, ...this.data.bet_filter,...this.data.search});
                    } else {
                        this.fire('getGGRSummary',{ ...this.data.bet_filter,...this.data.search});

                    };

                }
                this.data.table.setData();

                this.$scope.set('table_searching', true);
            }
        },

        reloadTable:{
            components:['api'],
            handler(e){
                if(this.data.table){
                    this.data.table.setData();
                };
            }
        },
        fuzzy:{
            filterByDateRange(name){
                if(['betting_logs','ggr'].includes(this.data.menu)){
                    this.data.search.date_range = name;
                };

                if(this.data.table){
                    this.data.table.setData();
                };
            },
            filterByBetStatus(name){
                if(['betting_logs'].includes(this.data.menu)){
                    this.data.search.bet_status = name;
                };

                if(this.data.table){
                    this.data.table.setData();
                };
            },
        },
        updateFilters:{
            components:['fuzzy','fuzzy_summary'],
            async handler(e){
                await this.fire.updateFilters();
                if(this.data.table){
                    this.data.table.setData();
                };
            }
        },
        spinner:{
            tableIsLoaded(){
                return this.data.isLoaded;
            }
        }
    },
});